<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card key="files-container" class="elevation-x">
    <v-sheet :color="$vuetify.theme.dark ? '' :'grey lighten-3'" class="pa-2">
      <v-row>
        <v-col cols="12" md="5">
          <v-text-field dense filled rounded placeholder="Recherche par nom ou tag" hide-details />
        </v-col>
        <!--        <v-col>-->
        <!--          <v-select dense filled rounded placeholder="filter par types" :items="filesTypes" multiple hide-details />-->
        <!--        </v-col>-->
        <!--        <v-col>-->
        <!--          <v-select dense filled rounded placeholder="ordre d'affichage" :items="sortingTypes" hide-details />-->
        <!--        </v-col>-->
        <v-spacer />
        <!--        <v-col class="align-self-center">-->
        <!--          <v-chip @click="SelectMultiple">{{ MultipleSelection ? 'Désactiver la selection multiple' : 'Activer la selection multiple' }}</v-chip>-->
        <!--        </v-col>-->
      </v-row>
    </v-sheet>
    <v-divider />
    <empty-list v-if="!loading && isEmpty" title="Aucun résultat"
                subtitle="Vous n'avez aucun document dans votre liste" actions="Ajouter un document" @action-empty="addDocument"
    />
    <v-list v-else three-line class="py-0" :flat="!MultipleSelection">
      <file-uploading-items-list />
      <v-list-item-group v-model="selectedItems" :multiple="MultipleSelection">
        <q-infinite-scroll ref="is" :disable="userFileList.endReached || loading" @load="fetch">
          <template v-if="userFileList">
            <template v-for="(item, index) in userFileList.items">
              <file-item :key="item" :file-uid="item" :multiple-selection="MultipleSelection" />
              <v-divider v-if="index + 1 < userFileList.items.length" :key="'divider-'+ item" class="my-0" />
            </template>
          </template>
          <template v-slot:loading>
            <v-progress-linear color="primary" indeterminate height="4" />
          </template>
        </q-infinite-scroll>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  import EmptyList from '@/modules/core/layout/EmptyList'
  import FileItem from '@/modules/files/components/FileItem'
  import {QInfiniteScroll} from 'quasar'
  import FileListMixin from '@/modules/files/mixins/file-list'
  import FileUploadingItemsList from '@/modules/files/components/upload/FileUploadingItemsList'
  import {FileList, Space} from '@/models'

  export default {
    name: 'UserFileList',
    components: {EmptyList, FileItem, QInfiniteScroll, FileUploadingItemsList},
    mixins: [FileListMixin],
    data() {
      return {
        MultipleSelection: false,
        loading: true,
        filesTypes: ['Imagerie', 'autres'],
        sortingTypes: ['Date de création', 'Nom de fichier'],
        selectedItems: []
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      isEmpty() {
        return this.userFileList && this.userFileList.items.length === 0 && this.userFileList.endReached
      },
      spaceFileList() {
        return FileList.get(this.space.fileList)
      }
    },
    async created() {
      await this.space.fetchFileList()
      this.loading = false
    },
    methods: {
      async fetch(index, done) {
        try {
          await this.userFileList.fetch(this.spaceFileList.uid)
          done(this.userFileList.endReached)
        } catch (e) {
          this.$handleApiError(e)
          done(true)
        }
      },
      SelectMultiple () {
        if(!this.MultipleSelection) {
          this.selectedItems = []
        }
        this.MultipleSelection = !this.MultipleSelection
      },
      addDocument() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/files/components/FileCreate')
        })
      }
    }
  }
</script>
