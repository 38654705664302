<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item
    transition="slide-y-transition"
    :disabled="$store.state.layout.searchActive"
    @click="openFile"
  >
    <template v-slot:default="{ active }">
      <v-list-item-avatar tile size="48">
        <transition name="slide-left" mode="out-in">
          <v-checkbox v-if="multipleSelection" :key="'checkbox-'+fileUid" :input-value="active" hide-details class="mb-1" dense />
          <file-mime-type-preview v-else :size="48" :mime-type="file.mime_type" />
        </transition>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="file.name" />

        <v-list-item-subtitle class="primary--text" v-text="file.by || $auth.user.name" />

        <v-list-item-subtitle class="font-xs lh-10 pb-1 op-80">
          Ajouté
          <base-date :date="file.date" />
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
  import BaseDate from '@/modules/core/components/BaseDate'
  import {File} from '@/models'
  import FileMimeTypePreview from '@/modules/files/components/FileMimeTypePreview'

  export default {
    name: 'FileItem',

    components: {FileMimeTypePreview, BaseDate},

    props: {
      fileUid: {
        type: String,
        required: true
      },
      multipleSelection: {
        type: Boolean
      }
    },

    computed: {
      file() {
        return File.get(this.fileUid)
      }
    },

    methods: {
      push(name) {
        this.$router.push({name, params: {uid: this.file.uid}})
      },
      openFile() {
        if(!this.multipleSelection) {
          this.$root.$emit('fileviewer:open', { uid: this.file.uid })
        }
      }
    }

  }
</script>
