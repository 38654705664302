<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <v-list-item>
      <v-list-item-avatar tile size="48">
        <file-mime-type-preview :size="48" :mime-type="item.file.fileType" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.data.name || item.file.name" />
        <v-list-item-subtitle v-text="item.by || $auth.user.name" />
        <v-list-item-subtitle>
          {{ text }} {{ item.clientData.progress }}%
        </v-list-item-subtitle>
      </v-list-item-content>

      <div class="progress-bar p-abs light-blue lighten-3 op-20 abs-l-0 abs-t-0 abs-b-0"
           :style="{width: item.clientData.progress + '%'}"
      />
    </v-list-item>

    <v-divider class="my-0" />
  </div>
</template>

<script>
  import {UPLOADING_STATUS} from '@/modules/files/store/files-upload'
  import FileMimeTypePreview from '@/modules/files/components/FileMimeTypePreview'

  export default {
    name: 'FileUploadingItem',
    components: {FileMimeTypePreview},
    props: {
      item: {
        type: Object,
        required: true
      },

      disableRemoveOnDone: {
        type: Boolean
      }
    },

    data: () => ({text: 'En attente...'}),

    watch: {
      ['item.clientData.progress'](val) {
        this.text = 100 === val ? 'Finalisation...' : 'En cours...'
      },

      ['item.clientData.status'](val) {
        if(!this.disableRemoveOnDone && UPLOADING_STATUS.done === val){
          this.$store.commit('files/upload/remove', this.item.clientData.id)
        }
      }
    }
  }
</script>
