<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list v-if="items.length">
    <file-uploading-item
      v-for="item in items"
      :key="item.clientData.id"
      :item="item"
    />
  </v-list>
</template>

<script>
  import FileUploadingItem from './FileUploadingItem'
  import {UPLOADING_STATUS} from '@/modules/files/store/files-upload'

  export default {
    name: 'FileUploadingItemsList',

    components: {FileUploadingItem},

    computed: {
      uploadingItems() {
        return this.$store.state.files.upload.items
      },

      items() {
        return this.uploadingItems.filter(v => UPLOADING_STATUS.done !== v.clientData.status)
      }
    }
  }
</script>